import Decimal from '@st/decimal'
import type { Freespin } from '../types'
import { useFreespinsStore } from '../stores/useFreespinsStore'
import { useBonusRolling } from './useBonusRolling'
import { useBonusesDeps } from '../useDeps'

export function useFreespin(freespin: Ref<Freespin>) {
  const { t } = useI18n()
  const router = useRouter()

  const freespinStore = useFreespinsStore()
  const { fetchFreespins } = freespinStore
  const { rollingGamesNames } = storeToRefs(freespinStore)
  const stFetch = useRawStFetch()
  const {
    isRollingStatusInProgress,
    isRollingStatusNewOrPaused,
    setCurrentRolling,
    rollingStatus,
  } = useBonusRolling(computed(() => freespin.value.rolling))

  const gameName = ref('')

  async function fetchGameTitle() {
    if (!freespin.value.freespinGameId) return

    const gameNameFromStore =
      rollingGamesNames.value[freespin.value.freespinGameId]

    if (gameNameFromStore) {
      gameName.value = gameNameFromStore
      return
    }
    const { data, error } = await stFetch('/casino/game/find', {
      method: 'post',
      body: {
        params: {
          gameId: [freespin.value.freespinGameId],
        },
        pagination: {
          page: 0,
          perPage: 50,
          orderBy: [
            {
              sortOrder: 'ASC',
              fieldName: 'name',
            },
          ],
        },
      },
    })

    if (error) {
      console.error(error)
      return
    }
    gameName.value = data.data?.[0]?.name ?? ''
    rollingGamesNames.value[freespin.value.freespinGameId] = gameName.value
  }
  watchEffect(() => {
    if (freespin.value?.freespinGameId) {
      fetchGameTitle()
    }
  })
  const { getCurrencyCodeById } = useBonusesDeps()
  const { format: formatFiat } = useCurrencyFormatter({
    currency: computed(
      () => getCurrencyCodeById(freespin.value.freespinCurrencyId) ?? 'usd',
    ),
  })

  const { format: formatMultiplier } = useNumberFormatter()

  const currentRollingAmount = computed(() =>
    formatFiat(freespin.value.rollingCurrentAmount ?? '0'),
  )

  const fullRollingAmount = computed(() =>
    formatFiat(freespin.value.rollingFullAmount ?? '1'),
  )

  const currentRewardAmount = computed(() =>
    formatFiat(freespin.value.freespinCurrentRewardAmount ?? '0'),
  )

  const freespinBetAmount = computed(() =>
    formatFiat(freespin.value.freespinBetAmount || 0),
  )

  const rollingProgress = computed(() => {
    if (
      !freespin.value.rollingCurrentAmount ||
      !freespin.value.rollingFullAmount
    )
      return ''
    const result = new Decimal(freespin.value.rollingCurrentAmount)
      .div(freespin.value.rollingFullAmount)
      .mul(100)
      .toFixed(2)
      .toString()

    return Decimal.min(result, 100).toString()
  })

  const bettingProgress = computed(() => {
    if (!freespin.value.freespinNumberLeft) return ''

    return new Decimal(freespin.value.freespinNumber)
      .minus(freespin.value.freespinNumberLeft)
      .div(freespin.value.freespinNumber)
      .mul(100)
      .toFixed(2)
      .toString()
  })

  const wager = computed(() =>
    formatMultiplier(freespin.value.rollingMultiplier ?? 0),
  )

  const status = computed(() => freespin.value.status)

  const title = computed(() => {
    if (status.value === 'new') {
      return t('bonuses.freespinPanelTitle', {
        number: freespin.value.freespinNumber,
        amount: freespinBetAmount.value,
      })
    }
    return t('bonuses.freespinForGame', { game: gameName.value })
  })

  function onSubmitClick() {
    if (isRollingStatusNewOrPaused.value && freespin.value.rolling?.id) {
      setCurrentRolling()
      return
    }
    switch (status.value) {
      case 'new':
        router.replace({
          query: {
            modal: 'chooseGame',
            gameIds: freespin.value.casinoGameToChooseIds,
            userFreespinProgramId: freespin.value.userFreespinProgramId,
          },
        })
        break
      case 'inProgress':
        router.push(`/casino/games/${freespin.value.freespinGameId}`)
        break
      case 'rolling':
        router.replace('/casino')
        break
      default:
        break
    }
  }

  const subtitle = computed(() => {
    if (freespin.value.status === 'new') {
      return t('bonuses.chooseGame')
    }
    return t('bonuses.forGame', { game: gameName.value })
  })

  const buttonLabel = computed(() => {
    if (rollingStatus.value === 'paused') return t('bonuses.resumeRolling')
    if (rollingStatus.value === 'new') return t('bonuses.startRolling')
    switch (freespin.value.status) {
      case 'inProgress':
        return t('bonuses.play')
      case 'rolling':
        return t('bonuses.makeFreespinBets')
      default:
        return t('bonuses.chooseGame')
    }
  })

  const timerTitle = computed(() => {
    if (isRollingStatusNewOrPaused.value)
      return t('bonuses.untilFinishConditions')
    switch (freespin.value.status) {
      case 'inProgress':
        return t('bonuses.activeDuringDate')
      case 'rolling':
        return t('bonuses.conditionsExpireAt')
      default:
        return t('bonuses.activationExpireAt')
    }
  })

  const isProccessingLastFreespin = computed(
    () =>
      !!(
        freespin.value.status === 'inProgress' &&
        freespin.value.freespinNumberLeft === 0 &&
        freespin.value.freespinCurrentRewardAmount
      ),
  )

  const list = computed(() => [
    {
      label: t('bonuses.denominationFS'),
      value: freespinBetAmount.value,
    },
    {
      label: t('bonuses.maxWin'),
      value: formatFiat(freespin.value.maxRewardAmount ?? 0),
    },
    {
      label: t('bonuses.spinCondition'),
      value: t('bonuses.wager', {
        multiplier: freespin.value.rollingMultiplier,
      }),
      disabled: freespin.value.rollingMultiplier === '0',
    },
  ])

  const filteredList = computed(() =>
    list.value.filter((item) => !item.disabled),
  )

  const buttonIcon = computed(() => {
    if (
      freespin.value.status === 'inProgress' ||
      isRollingStatusInProgress.value
    )
      return 'play-solid'
    return undefined
  })

  const buttonType = computed(() => {
    if (isRollingStatusNewOrPaused.value) {
      return 'secondary'
    }
    return 'primary'
  })

  return {
    title,
    status,
    wager,
    bettingProgress,
    rollingProgress,
    currentRewardAmount,
    currentRollingAmount,
    fullRollingAmount,
    gameName,
    onSubmitClick,
    subtitle,
    buttonLabel,
    timerTitle,
    isProccessingLastFreespin,
    filteredList,
    formatFiat,
    freespinBetAmount,
    rollingStatus,
    buttonType,
    isRollingStatusNewOrPaused,
    buttonIcon,
    isRollingStatusInProgress,
    fetchFreespins,
  }
}
